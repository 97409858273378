<template>
  <b-modal :visible="showModal" title="Enviar mensagens" no-close-on-backdrop :hide-header="true" :hide-footer="true"
    size="lg">
    <div class="row" style="justify-content: right;">
      <div class="float-right"><img @click="hideModal(false)" class="close" src="img/x-icon.svg" /></div>
    </div>
    <div class="row">
      <h4 style="padding-left: 20px;">Escolha uma mensagem</h4>
      <div class="col-lg-12 order-lg-2">
        <div class="text-center">
          <div class="row">
            <div class="col-md-12">
              <div :class="clicked1 && 'selected'" class="card-mensagem mb-4" @click.prevent="mensagem = '667c661ff1e66a001255c216';
              clicked1 = true;
              clicked2 = false;
              clicked3 = false;
              clicked4 = false;
              clicked5 = false;">
                <p>@primeironome! Sabia que sua assinatura está prestes a vencer e você ficará sem a
                  proteção para sua bike? Não corra esse risco! Fale comigo e eu te mando as melhores condições para a
                  sua renovação.</p>
              </div>
              <div :class="clicked2 && 'selected'" class="card-mensagem mb-4" @click.prevent="mensagem = '667c661ff1e66a001255c215';
              clicked1 = false;
              clicked2 = true;
              clicked3 = false;
              clicked4 = false;
              clicked5 = false;">
                <p>Opa, @primeironome! Aqui é a @atendente, do seguro Bike Registrada. Espero que você esteja bem!

                  Estou entrando em contato para informar que chegou a hora de renovar a sua proteção, e tenho uma
                  OFERTA EXCLUSIVA para você!</p>
              </div>
              <div :class="clicked3 && 'selected'" class="card-mensagem mb-4" @click.prevent="mensagem = '667c661ff1e66a001255c214';
              clicked1 = false;
              clicked2 = false;
              clicked3 = true;
              clicked4 = false;
              clicked5 = false;">
                <p>Fala, @primeironome! Está chegando a hora de renovar o seu seguro, e nós preparamos uma condição
                  muito especial para ti!</p>
              </div>
              <div :class="clicked4 && 'selected'" class="card-mensagem mb-4" @click.prevent="mensagem = '649d84862d1bd70012bd463f';
              clicked1 = false;
              clicked2 = false;
              clicked3 = false;
              clicked4 = true;
              clicked5 = false;">
                <p>Fala, @PRIMEIRONOME! Espero que esteja tudo ótimo por aí.

                  Aqui é a Bia, do Seguro Bike Registrada.

                  Passando só para informar que tivemos um contratempo e não conseguimos efetuar a renovação da sua
                  bike @modelo. Dessa forma, a sua proteção está PENDENTE.

                  Mas não tem problemas! Você pode regularizar agora a sua proteção através do cartão de crédito ou,
                  se preferir, via Pix. Basta clicar no seu link personalizado: Link

                  🚨E vale lembrar que, caso regularize sua assinatura via cartão de crédito e marque a opção "Usar
                  este cartão de crédito em cobranças futuras", você habilita a renovação automática no cartão
                  informado :)

                  Restando alguma dúvida ou precisando de algo é só responder a essa mensagem!

                  Forte abraço e ótimas pedaladas 🚴‍♀️</p>
              </div>
              <div :class="clicked5 && 'selected'" class="card-mensagem mb-4" @click.prevent="mensagem = '6499af2beb236e0012000011';
              clicked1 = false;
              clicked2 = false;
              clicked3 = false;
              clicked4 = false;
              clicked5 = true;">
                <p>⚠️ IMPORTANTE ⚠️

                  O seguro da sua bike está pendente.
                  Entre em contato imediatamente com seu especialista bike 🚴🏻‍♀️</p>
              </div>

            </div>
          </div>
          <div class="row pl-5 pr-5 pb-2">
            <b-button :disabled="!clicked1 && !clicked2 && !clicked3 && !clicked4 && !clicked5" class="btn-block"
              style="border-radius: 50px;" variant="success" @click.prevent="enviarTemplate">
              Enviar mensagem</b-button>
          </div>
        </div>
      </div>
    </div>
    <Loading :criandoSeguro="true" v-if="loading" />
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import formatacaoData from "../../helpers/formatacaoData";
import Loading from "../Usuario/Loading.vue";

export default {
  props: ["selected", "showModal", "idCobranca"],
  components: {
    Loading,
  },
  data() {
    return {
      config,
      formatacaoData,
      loading: false,
      clicked1: "",
      clicked2: "",
      clicked3: "",
      clicked4: "",
      clicked5: "",
      mensagem: "",
      cobrancas:[]
    };
  },

  methods: {
    hideModal(value) {
      this.$emit("hide", { modificou: value, cobrancas: this.cobrancas?.data });
      this.clicked1 = "";
      this.clicked2 = "";
      this.clicked3 = "";
      this.clicked4 = "";
      this.clicked5 = "";
      this.loading = false;
      this.mensagem = "";
    },

    async enviarTemplate() {
      this.loading = true;
      try {
        var strDaadosUserInfor = localStorage.getItem("UserInfo");
        var dados = JSON.parse(strDaadosUserInfor);

        var data = this.selected.map((item) => {
          return {
            ...item,
            idUsuario: dados.id,
            nomeUsuario: dados.login,
            mensagem: this.mensagem,
          }
        });

        this.cobrancas = await axios.post(`${config.API_URLV2}/AnaliseCobranca/enviarTemplate`, data);
        Swal.fire(
          "Notificado",
          "Mensagens enviadas com sucesso!",
          "success"
        );
        this.hideModal(true);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            (error.response &&
              error.response.data) || "Não foi possível consultar as cobranças. Tente novamente mais tarde ou contate o suporte.",
        });
      }

    },
  },
};
</script>

<style scoped>
.close {
  padding-top: 0px !important;
  padding-right: 23px !important;
  font-size: 25px;
  width: 45px;
  color: black;
}

.selected {
  background-color: #4FA935 !important;
}

.card-mensagem {
  cursor: pointer;
  text-align: left;
  color: #FFFFFF;
  padding: 24px;
  background-color: #78849E;
  border-radius: 12px;
}
</style>
