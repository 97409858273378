<template>
  <div class="minha-conta">
    <header class="bg-dark py-2">
      <div class="container">
        <div class="row">
          <div class="col">
            <router-link to="/">
              <img src="/img/logo.svg" width="150" height="34" />
            </router-link>
            <span class="badge badge-success ml-2">admin</span>
          </div>

          <div class="col text-right">
            <SideNav />
          </div>
        </div>
      </div>
    </header>
    <div class="bg-gradient">
      <div class="container text-center pt-3">
        <h3>Análise Cobranças</h3>
        <div class="h-line" style="width:142px;"></div>
      </div>
    </div>

    <div class="container mt-5 pb-5">
      <div class="row mb-4">
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                Nome
              </span>
            </div>
            <input type="text" class="form-control" v-model="nome" />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                Telefone
              </span>
            </div>
            <input type="text" class="form-control" v-mask="['(##) ####-####', '(##) #####-####']" v-model="telefone" />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">
                CPF
              </span>
            </div>
            <input type="text" class="form-control" v-mask="'###.###.###-##'" v-model="cpf" />
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">Status</span>
            </div>

            <b-form-select v-model="tipoAssinatura">
              <option :value="null">Todos </option>
              <option :value="config.ID_TIPO_ASSINATURA_MENSAL">Mensal</option>
              <option :value="config.ID_TIPO_ASSINATURA_ANUAL">Anual </option>
            </b-form-select>
          </div>
        </div>

        <div class="col-md-4 mb-2">
          <button type="submit" class="btn btn-success btn-block mb-3" @click.prevent="buscarCobrancas()"
            :disabled="loading">
            <span v-if="!loading">Buscar cobrança</span>
            <span v-if="loading">Por favor aguarde...</span>
          </button>
        </div>
      </div>
      <div class="row mb-4 end-justify">
        <div class="row">
          <div class="col-lg-12 order-lg-2">
            <div v-if="!loading">
              <table class="table text-center" v-if="cobrancas.length > 0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Assinatura</th>
                    <th>Data Vencido</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>CPF</th>
                    <th>Telefone</th>
                    <th>
                      <div class="display-inline-flex">
                        <div class="margin-5">
                          Valor
                        </div>
                        <div class="display-inline-grid ">
                          <i class="fas fa-chevron-up fa-fw" @click="ordenarMenorValor()"></i>
                          <i class="fas fa-chevron-down fa-fw" @click="ordenarMaiorValor()"></i>
                        </div>
                      </div>
                    </th>
                    <th>Fatura</th>
                    <th width="12%">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in cobrancas" :key="index">
                    <td><b-form-checkbox v-model="selected" :value="item"></b-form-checkbox></td>
                    <td>
                      <span v-if="item.tipoAssinatura == 'Anual'" class="badge badge-warning">{{ item.tipoAssinatura
                        }}</span>
                      <span v-else class="badge badge-success">{{ item.tipoAssinatura }}</span>
                    </td>
                    <td>
                      {{ formatacaoData.formatarDataHoraLocal(item.dataCriado) }}
                    </td>
                    <td>
                      {{ item.nome }}
                    </td>
                    <td>
                      {{ item.email }}
                    </td>
                    <td width="12%">
                      {{ helper.formatacaoCpf(item.cpf) }}
                    </td>
                    <td width="15%">
                      {{ formatacaoTelefone(item.telefone) }}
                    </td>
                    <td>
                      {{ formatacaoMoeda(item.valor) }}
                    </td>
                    <td width="12%">
                      <a @click="buscarLinkFatura(item.idFatura)" href="javascript:void(0);">
                        <span> Buscar Fatura</span>
                      </a>
                    </td>
                    <td>
                      <div class="row margin-row ">
                        <div class="col-6 ">
                          <button type="button" @click="abrirModalAnaliseCobranca(item.idCobranca)"
                            class="btn btn-outline-success btn-sm">
                            <i class="fas fa-plus fa-fw"></i>
                          </button>
                        </div>

                        <div class="col-6  ">
                          <b-badge class="badge-custtom " v-if="item.quantidade > 0" variant="success">{{
                            item.quantidade
                          }}</b-badge>
                          <b-badge class="badge-custtom" v-if="item.erroEnvioMensagem" variant="danger">Mensagem não
                            enviada</b-badge>
                          <button type="button" @click="abrirHistorico(item.idCobranca)"
                            class=" btn btn-outline-success btn-sm">
                            <i class="fa fa-list"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Loading :criandoSeguro="true" v-if="loading" />
      <ModalHistoricoCobranca :showModalHistorico="showModalHistorico" :idCobranca="idCobranca"
        @hide="fecharModalHistorico" />
      <ModalAnaliseCobranca :selected="selected" :showModal="showModal" :idCobranca="idCobranca"
        @hide="fecharModalAnaliseCobranca" @hideModalF="fecharModalAnaliseCobrancaVarios" />
      <ModalMensagem :selected="selected" :showModal="showModalMensagem" :idCobranca="idCobranca"
        @hide="fecharModalMensagem" />
      <div v-if="selected.length > 0" class="retangulo-flutuante">
        <div><b-button class="mb-4" variant="success" @click="showModalMensagem = true">Mensagem</b-button></div>
        <div><b-button variant="success" @click="abrirModalAnaliseCobranca(null)">FeedBack</b-button></div>
      </div>

    </div>
  </div>
</template>

<script>
import SideNav from "../../components/Admin/SideNav";
import config from "../../config";
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import formatacaoData from "../../helpers/formatacaoData";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";
import helper from "../../helpers/helper";
import ModalHistoricoCobranca from "../../components/Admin/ModalHistoricoCobranca.vue";
import ModalAnaliseCobranca from "../../components/Admin/ModalAnaliseCobranca.vue";
import ModalMensagem from "../../components/Admin/ModalMensagem.vue";
import Loading from "../../components/Usuario/Loading.vue";
export default {
  components: {
    SideNav,
    ModalHistoricoCobranca,
    ModalAnaliseCobranca,
    ModalMensagem,
    Loading,
  },
  data() {
    return {
      config,
      loading: false,
      cobrancas: [],
      selected: [],
      idUsuarioAdm: undefined,
      formatacaoMoeda,
      formatacaoData,
      formatacaoTelefone,
      nome: null,
      email: null,
      telefone: null,
      tipoAssinatura: null,
      cpf: null,
      helper,
      loadingText: false,
      showModalMensagem: false,
      //Modal
      showModalHistorico: false,
      idCobranca: undefined,

      showModal: false,
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (userInfo && userInfo.cargo) {
      this.idUsuarioAdm = userInfo.id;
    } else {
      this.$router.replace("/login");
    }
  },
  mounted() {
    this.buscarCobrancas();
  },
  methods: {
    ordenarMaiorValor() {
      this.cobrancas.sort((a, b) => a.valor - b.valor);
    },
    ordenarMenorValor() {
      this.cobrancas.sort((a, b) => b.valor - a.valor);
    },
    fecharModalHistorico() {
      this.showModalHistorico = false;
    },
    abrirHistorico(item) {
      this.showModalHistorico = true;
      this.idCobranca = item;
    },
    abrirModalAnaliseCobranca(item) {
      this.showModal = true;
      this.idCobranca = item;
    },
    fecharModalAnaliseCobranca(value) {
      this.showModal = false;
      if (value?.modificou) {
        this.cobrancas.forEach(element => {
          if (element.idCobranca === value.idCobranca) {
            element.quantidade++;
          }
        });
      }
    },
    fecharModalAnaliseCobrancaVarios(values) {
      this.showModal = false;
      if (values?.modificou) {
        this.cobrancas.forEach(element => {
          if (values.cobrancas.find(w => w.idCobranca == element.idCobranca)) {
            element.quantidade++;
          }
        });
        this.selected = [];
      }
    },
    fecharModalMensagem(values) {
      this.showModalMensagem = false;

      if (values?.modificou) {
        this.cobrancas.forEach(element => {
          const cobranca = values.cobrancas.find(w => w.idCobranca == element.idCobranca);
          if (cobranca != null) {
            element.quantidade++;
            element.erroEnvioMensagem = cobranca.erroEnvioMensagem;
          }
        });
        this.selected = [];
      }
    },
    async buscarCobrancas() {
      var data = {
        email: this.email,
        nome: this.nome,
        telefone: this.telefone,
        cpf: this.cpf,
        tipoAssinatura: this.tipoAssinatura,
      };

      this.loading = true;
      try {
        var response = await axios.post(
          `${config.API_URLV2}/AnaliseCobranca/Buscar`,
          data
        );

        if (response.data.length === 0) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            html: `Não foi encontrada nenhuma cobrança para essa pesquisa`,
          });
        }

        this.cobrancas = response.data;
        this.selected = [];

        this.loading = false;
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível consultar as cobranças. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    async buscarLinkFatura(idFaturaIugu) {
      try {
        var link = await axios.get(
          `${config.API_URLV2}/CobrancaSeguro/BuscarLinkFatura/${idFaturaIugu}`
        );

        window.open(link.data, "_blank");
      } catch (error) {
        this.$toast.error("não foi possivel buscar a fatura");
      }
    },
  },
};
</script>
<style scoped>
.retangulo-flutuante {
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.display-inline-grid {
  display: inline-grid;
  cursor: pointer;
}

.margin-5 {
  margin: 5px;
}

.display-inline-flex {
  display: inline-flex;
}

.minha-conta {
  height: 100%;
  /* background-color: #f7f7f7; */
}

.bg-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.14609593837535018) 0%,
      rgba(255, 255, 255, 0) 70%);
}

.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.not-collapsed .when-closed,
.collapsed .when-open {
  display: none;
}

.item {
  position: relative;
  padding-top: 20px;
  display: inline-block;
}

.badge-custtom {
  position: absolute;
  right: 3px;
  top: -6px;
  text-align: center;
  color: white;
  padding: 2px 5px;
  font-size: 7px;
}

.margin-row {
  margin: 0px;
}

@media (max-width: 1199px) {
  .badge-custtom {
    position: absolute;
    right: -6px;
    top: -6px;
    text-align: center;
    color: white;
    padding: 2px 5px;
    font-size: 7px;
  }
}

.end-justify {
  justify-content: end;
}

@media (max-width: 991px) {
  .margin-row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .badge-custtom {
    position: absolute;
    right: -12px;
    top: -5px;
    text-align: center;
    color: white;
    padding: 2px 5px;
    font-size: 7px;
  }
}
</style>
